import { Logger } from 'tslog';
import getAbsoluteURL from '../getAbsoluteUrl';
import { MessageBodyType } from '../../pages/api/_';


export const logger = new Logger<MessageBodyType>({ name: 'myLogger' });

export function info(message: string, additional: unknown = {}) {
  logger.info({
    message,
    additional,
    timestamp: Date.now(),
    level: 'info',
  });
}

export function error(error: unknown) {
  logger.error({
    message: hasProperty('message', error) ? error.message : 'Unknown error', // TODO: fix
    timestamp: Date.now(),
    level: 'error',
    additional: {
      error: error,
      stack: hasProperty('stack', error) ? JSON.stringify(error.stack) : 'NA',
    },
  });
}

function hasProperty<Prop extends string>(
  property: Prop,
  obj: unknown
): obj is Record<Prop, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

logger.attachTransport((logEntry: MessageBodyType) => {
  const url = getAbsoluteURL('/api/_');
  fetch(url, {
    method: 'POST',
    body: JSON.stringify(logEntry),
  });
});
