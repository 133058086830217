import {
  AssetClass,
  CborData,
  ConstrData,
  HInt,
  HMap,
  IntData,
  ListData,
  MapData,
  PubKeyHash,
  Time,
  UplcData,
  UplcProgram,
  textToBytes,
} from '@hyperionbt/helios';
import {
  NetworkParams,
  TxOutput,
  TxWitnesses,
  Value,
  Tx,
  UTxO,
  TxId,
  Address,
  Program,
  Datum,
  MintingPolicyHash,
  hexToBytes,
  Assets,
  bytesToHex,
  TxRefInput,
} from '@hyperionbt/helios';
import { ChainUTxO } from '../../server/routes/asset/utxo';
import constants from '../constants';
import getAbsoluteURL from '../getAbsoluteUrl';
import dayjs from 'dayjs';

import { CardanoWalletExtended } from '../wallet/WalletContext';
import {
  CoinSelectionErrorCode,
  DropspotMarketError,
  InternalErrorCode,
  APIError,
} from './DropspotMarketError';
import { Asset, CONTRACT_TYPES } from '../../types';

import firebase from 'firebase/app';
import 'firebase/auth';
import { InferQueryOutput, trpcClient } from '../../lib/trpc';
import { Cardano } from '../../types/cardano';
import { captureException } from '@sentry/nextjs';
import { z } from 'zod';
import { utils } from '@dropspot-io/contract-api';
import { coinSelection } from './CoinSelection';
import { logger } from '../Logger';

const { ensureCollateralUTxO } = utils;

export type BUILD_ACTION =
  | 'Building'
  | 'Finalizing'
  | 'Signing'
  | 'Submitting'
  | 'Submitted';
export class DropspotMarketContract {
  private _compiled: UplcProgram;
  private _address: Address;
  private _networkParams: NetworkParams | undefined;
  private contractType: CONTRACT_TYPES;

  private listener: (action: BUILD_ACTION) => void;

  constructor(
    scriptCbor: string,
    contractType: CONTRACT_TYPES,
    isTestnet: boolean,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    listener: (action: BUILD_ACTION) => void = () => {}
  ) {
    this.listener = listener;
    this.contractType = contractType;

    const dsAddress = Address.fromBech32(
      constants.NEXT_PUBLIC_DROPSPOT_ADDRESS
    );

    // this.program = Program.new(contractText);
    // this.contractText = contractText;

    console.log('contract type: ', contractType);

    // if (this.contractText.includes('DS_ADDRESS')) {
    //   this.program.changeParam(
    //     'DS_ADDRESS',
    //     JSON.stringify(dsAddress.pubKeyHash.bytes)
    //   );
    // }

    this._compiled = UplcProgram.fromCbor(hexToBytes(scriptCbor));

    const stakingHash = dsAddress.stakingHash;

    this._address = Address.fromValidatorHash(
      this._compiled.validatorHash,
      stakingHash,
      isTestnet
    );
  }

  private async getNetworkParams() {
    if (!this._networkParams) {
      const np = await fetch(constants.NETWORK_PARAMS_URL).then((r) =>
        r.json()
      );

      this._networkParams = new NetworkParams(np); // Get Network Params
    }

    return this._networkParams;
  }

  private datumToMyDatum(
    ownerAddress: Address,
    heliosVersion: '12' | '13',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inlineDatum: any | undefined,
    onChainDatum: string,
    royalties?: { address: string; percent: number }[],
    disbursements?: { address: string; percent: number }[]
  ): [DSDatum, 'INLINE' | 'HASHED'] {
    console.log('Datum is Inline Datum', JSON.stringify(inlineDatum));

    if (isInlineDatum(inlineDatum, heliosVersion)) {
      // Inline datum is only available on Sale types
      return handleInlineDatum(
        heliosVersion,
        inlineDatum,
        ownerAddress,
        royalties,
        disbursements
      );
    }

    const uplcData = UplcData.fromCbor(hexToBytes(onChainDatum));

    const json = JSON.parse(uplcData.toSchemaJson());
    if (!isValidDatumJson(json))
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Bad inline datum - 10',
      });

    const price = (json.fields[3] as IntDatum).int;
    const [policy, asset] = (json.fields[4] as ValidDatum).fields;
    const startDate = (json.fields[5] as IntDatum).int;

    if (json.fields.length === 8) {
      const dropspotRate = 1000 / (json.fields[6] as IntDatum).int;
      const cancelFee = (json.fields[7] as IntDatum).int;
      return [
        {
          type: 'Sale-Mint',
          datum: {
            version: heliosVersion,
            ownerAddress: ownerAddress.toBech32(),
            amount: price,
            royalties: royalties ? royalties : [],
            disbursements: disbursements || [],
            policy: (policy as BytesDatum).bytes,
            tokenName: (asset as BytesDatum).bytes,
            startDatePOSIX: `${startDate}`,
            cancelFee,
            dropspotRate,
          },
        },
        'HASHED',
      ];
    } else {
      return [
        {
          type: 'Sale',
          datum: {
            version: heliosVersion,
            ownerAddress: ownerAddress.toBech32(),
            amount: price,
            royalties: royalties ? royalties : [],
            disbursements: disbursements || [],
            policy: (policy as BytesDatum).bytes,
            tokenName: (asset as BytesDatum).bytes,
            startDatePOSIX: `${startDate}`,
          },
        },
        'HASHED',
      ];
    }
  }

  public async relist(
    assetId: string,
    chainUtxo: ChainUTxO,
    price: string,
    wallet: CardanoWalletExtended,
    assetStandard: 'CIP68' | 'CIP25',
    txInputHash?: string,
    txInputIndex?: number
  ) {
    const [datum, inline] = this.extractDSDatum(chainUtxo);

    const networkParams = await this.getNetworkParams();
    const redeemer = this.redeemer({
      action: 'Relist',
      price,
      startDate: datum.datum.startDatePOSIX,
    });

    const address = await getAddress(wallet);
    const walletAddress = Address.fromHex(address);
    const tx = new Tx();

    tx.addSigner(walletAddress.pubKeyHash);
    this.setTxValidityPeriod(tx, 0);

    const amountFromWalletADA = BigInt(5_000_000); // Some ADA to cover the Transaction Fees
    const addedWalletUTxOs = await this.addInputsFromWallet(
      amountFromWalletADA,
      tx,
      wallet
    );
    const assets = new Assets();
    chainUtxo.value.forEach((asset) => {
      const mph = MintingPolicyHash.fromHex(asset.policy);
      const token = hexToBytes(asset.name);
      console.log('Asset', bytesToHex(token), asset.name);
      assets.addComponent(mph, token, BigInt(asset.amount));
    });

    const value = new Value(BigInt(chainUtxo.lovelace), assets);

    const { pure } = await this.datum(datum);

    const updatedDatum = { ...datum };
    updatedDatum.datum.amount = Number.parseInt(price, 10);
    updatedDatum.datum.assetStandard = assetStandard;
    // updatedDatum.datum.startDatePOSIX = `${startDate}`;

    const { pure: dtmNew } = await this.datum(updatedDatum);
    console.log(
      'updatedDatum',
      updatedDatum,
      bytesToHex(Datum.inline(dtmNew).toCbor())
    );

    const txOutput = new TxOutput(
      Address.fromBech32(chainUtxo.address),
      value,
      inline === 'INLINE' ? Datum.inline(pure) : Datum.hashed(pure)
    );
    const heliosUtxo = new UTxO(
      TxId.fromHex(chainUtxo.txhash) as TxId,
      BigInt(chainUtxo.index),
      txOutput
    );

    tx.addInput(heliosUtxo, redeemer);

    // Add the input back to the script, adding the updated Datum
    tx.addOutput(
      this.createSafeTxOutput(
        networkParams,
        this._address,
        value,
        Datum.inline(dtmNew)
      )
    );

    if (
      chainUtxo.ref_input_value &&
      txInputHash &&
      txInputIndex !== undefined
    ) {
      tx.addRefInput(
        new TxRefInput(
          TxId.fromHex(txInputHash) as TxId,
          BigInt(txInputIndex),
          new TxOutput(
            Address.fromBech32(chainUtxo.ref_input_value.address),
            new Value(BigInt(chainUtxo.ref_input_value.lovelace))
          )
        ),
        this._compiled
      );
    } else {
      tx.attachScript(this._compiled);
    }

    this.balanceAssets(
      walletAddress,
      addedWalletUTxOs.selectedUtxos,
      tx,
      networkParams
    );
    this.setAddressTxMetadata(walletAddress, tx);

    const { otherUtxos } = await ensureCollateralUTxO({
      wallet,
      ...addedWalletUTxOs,
    }).catch(() => {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.NO_COLLATERAL,
        info: 'No Collateral available in wallet UTxO set',
      });
    });

    console.log('Tx >>>>>>>> ', tx.toCborHex());

    const builtTx = await tx.finalize(networkParams, walletAddress, otherUtxos);

    console.log('doCancel', 'Send for Signing');
    const signatures = await this.signTransaction(builtTx, wallet);

    builtTx.addSignatures(signatures);

    console.log('doCancel', 'Submit Txn', builtTx.witnesses.dump());
    return this.submitTx(bytesToHex(builtTx.toCbor()), assetId);
  }

  private balanceAssets(
    walletAddress: Address,
    addedWalletUTxOs: UTxO[],
    tx: Tx,
    networkParams: NetworkParams,
    ignoreAssets?: Assets // These are assets that we do not want to create a 'change' output for.
  ) {
    let balanceAssets = addedWalletUTxOs
      .map((wUtxo) => wUtxo.value.assets)
      .reduce((allAssets, assets) => allAssets.add(assets), new Assets());

    console.log(bytesToHex(balanceAssets.toCbor()));
    if (ignoreAssets) {
      console.log('ignoreAssets', bytesToHex(ignoreAssets.toCbor()));
      balanceAssets = balanceAssets.applyBinOp(ignoreAssets, (a, b) => a - b);
    }
    // if (ignoreAssets) {
    //   const newBalanceAssets = new Assets();
    //   ignoreAssets.mintingPolicies
    //     .reduce((data, current) => {
    //       const tokens = ignoreAssets.getTokenNames(current).reduce((t, ct) => {
    //         if (!balanceAssets.has(current, ct)) {
    //           t.add({ token: ct, quantity: ignoreAssets.get(current, ct) });
    //         }
    //         return t;
    //       }, new Set<{ token: number[]; quantity: bigint }>());

    //       if (tokens.size > 0) {
    //         tokens.forEach((token) => {
    //           data.add({ mph: current, ...token });
    //         });
    //       }

    //       return data;
    //     }, new Set<{ mph: MintingPolicyHash; token: number[]; quantity: bigint }>())
    //     .forEach((e) => {
    //       newBalanceAssets.addComponent(e.mph, e.token, e.quantity);
    //     });
    //   balanceAssets = newBalanceAssets;
    // }

    console.log(bytesToHex(balanceAssets.toCbor()), balanceAssets.isZero());
    if (!balanceAssets.isZero()) {
      // Create a TXout to the Change Address with these Unbalanced Assets
      tx.addOutput(
        this.createSafeTxOutput(
          networkParams,
          walletAddress,
          new Value(BigInt(0), balanceAssets)
        )
      );
    }

    return tx;
  }

  public async cancelListing(
    assetId: string,
    chainUtxo: ChainUTxO,
    wallet: CardanoWalletExtended,
    txInputHash?: string,
    txInputIndex?: number
  ) {
    console.log(
      'this._address.toBech32() !== chainUtxo.address',
      this._address.validatorHash.dump(),
      Address.fromBech32(chainUtxo.address).validatorHash.dump(),
      chainUtxo.address,
      this._address.toBech32()
    );

    this.listener('Building');

    const [datum, inline] = this.extractDSDatum(chainUtxo);

    const networkParams = await this.getNetworkParams();
    const redeemer = this.redeemer({ action: 'Cancel' });

    const address = await getAddress(wallet);
    const walletAddress = Address.fromHex(address);
    const tx = new Tx();

    tx.addSigner(walletAddress.pubKeyHash);

    const cancelFee =
      datum.type === 'Sale'
        ? chainUtxo.type === 'Sale-WCF'
          ? 5_000_000
          : 0
        : datum.datum.cancelFee;

    const amountFromWalletADA =
      BigInt(cancelFee + 2_000_000) + BigInt(chainUtxo.lovelace);

    const addedWalletUTxOs = await this.addInputsFromWallet(
      amountFromWalletADA,
      tx,
      wallet
    );

    const assets = new Assets();
    chainUtxo.value.forEach((asset) => {
      const mph = MintingPolicyHash.fromHex(asset.policy);
      const token = hexToBytes(asset.name); //CIP68

      assets.addComponent(mph, token, BigInt(asset.amount));
    });

    const value = new Value(BigInt(chainUtxo.lovelace), assets);

    const { pure } = await this.datum(datum);

    const txOutput = new TxOutput(
      Address.fromBech32(chainUtxo.address),
      value,
      inline === 'INLINE' ? Datum.inline(pure) : Datum.hashed(pure)
    );
    const heliosUtxo = new UTxO(
      TxId.fromHex(chainUtxo.txhash) as TxId,
      BigInt(chainUtxo.index),
      txOutput
    );

    tx.addInput(heliosUtxo, redeemer);

    tx.addOutput(
      this.createSafeTxOutput(
        networkParams,
        walletAddress,
        new Value(BigInt(0), assets)
      )
    );

    if (cancelFee !== 0) {
      tx.addOutput(
        this.createSafeTxOutput(
          networkParams,
          Address.fromBech32(constants.NEXT_PUBLIC_DROPSPOT_ADDRESS),
          // Pay Dropspot the Cancel Fee + the ADA that is currently at the Contract Address
          new Value(
            BigInt(cancelFee) +
              (datum.type === 'Sale-Mint'
                ? BigInt(chainUtxo.lovelace)
                : BigInt(0))
          )
        )
      );
    }

    if (
      chainUtxo.ref_input_value &&
      txInputHash &&
      txInputIndex !== undefined
    ) {
      tx.addRefInput(
        new TxRefInput(
          TxId.fromHex(txInputHash) as TxId,
          BigInt(txInputIndex),
          new TxOutput(
            Address.fromBech32(chainUtxo.ref_input_value.address),
            new Value(BigInt(chainUtxo.ref_input_value.lovelace))
          )
        ),
        this._compiled
      );
    } else {
      tx.attachScript(this._compiled);
    }

    // collateral.map((c) => {
    //   const utxo = UTxO.fromCbor(hexToBytes(c)) as UTxO;
    //   console.log('doCancel', 'Collateral UTXO', utxo.value.lovelace);
    //   tx.addCollateral(utxo);
    // });

    this.balanceAssets(
      walletAddress,
      addedWalletUTxOs.selectedUtxos,
      tx,
      networkParams
    );

    this.setTxValidityPeriod(tx, 0);
    this.listener('Finalizing');

    const { otherUtxos } = await ensureCollateralUTxO({
      wallet,
      ...addedWalletUTxOs,
    }).catch(() => {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.NO_COLLATERAL,
        info: 'No Collateral available in wallet UTxO set',
      });
    });

    const builtTx = await tx.finalize(networkParams, walletAddress, otherUtxos);

    const signatures = await this.signTransaction(builtTx, wallet);

    builtTx.addSignatures(signatures);

    return this.submitTx(bytesToHex(builtTx.toCbor()), assetId);
  }

  /**
   * Creates a TxOutput that has the Correct MinADA set.
   * @param networkParams
   * @param address
   * @param value
   * @param datum
   * @param refScript
   * @returns
   */
  private createSafeTxOutput(
    networkParams: NetworkParams,
    address: Address,
    value: Value,
    datum?: Datum,
    refScript?: UplcProgram
  ) {
    const txOutput = new TxOutput(address, value, datum, refScript);
    txOutput.correctLovelace(networkParams);

    if (txOutput.value.lovelace < BigInt(1_000_000)) {
      txOutput.value.setLovelace(BigInt(1_000_000));
    }

    return txOutput;
  }

  private extractDSDatum(chainUtxo: ChainUTxO) {
    if (chainUtxo.dsDatum) {
      return [chainUtxo.dsDatum, 'HASHED'] as const;
    }

    if (!chainUtxo.onchain_datum && !chainUtxo.inline_datum) {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'No Inline or Onchain Datum',
      });
    }

    const sellerAddress = Address.fromHex(chainUtxo.assetowner.join(''));

    const [myDatum, inline] = this.datumToMyDatum(
      sellerAddress,
      chainUtxo.helios_version,
      chainUtxo.inline_datum,
      joinStringIfArray(chainUtxo.onchain_datum || ''),
      chainUtxo.royalties ? chainUtxo.royalties : undefined,
      chainUtxo.disbursements
    );

    if (!myDatum) {
      throw new DropspotMarketError({
        type: 'BUILD',
        code: InternalErrorCode.MissingDatum,
        info: 'No onChain Datum found',
      });
    }

    return [myDatum, inline] as const;
  }

  public async maestroToChainUtxo(
    assetInfo: InferQueryOutput<'asset-utxo-2'>[number],
    utxo: InferQueryOutput<'maestro-utxo'>
  ) {
    if (assetInfo.type === 'JPG_TXN') {
      throw new DropspotMarketError({
        code: InternalErrorCode.UNEXPECTED,
        info: 'Invalid UTXO Type (URL)',
        type: 'INTERNAL',
      });
    }
    if (assetInfo.type === 'URL') {
      throw new DropspotMarketError({
        code: InternalErrorCode.UNEXPECTED,
        info: 'Invalid UTXO Type (URL)',
        type: 'INTERNAL',
      });
    }

    if (!assetInfo.asset_owner) {
      throw new DropspotMarketError({
        code: InternalErrorCode.UNEXPECTED,
        info: 'No Asset Owner found',
        type: 'INTERNAL',
      });
    }

    const { bytes, hash, calculated, json } = assetInfo.datum || {};

    if (!hash) {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Missing Datum - Hash',
      });
    }

    if (!bytes && !calculated && !json) {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Missing Datum',
      });
    }

    const royalties = assetInfo.royalties;
    const datumCbor = bytes || (await this.datum(calculated!)).datum;

    const sellerAddress = Address.fromHex(assetInfo.asset_owner.join(''));
    const datumJson =
      calculated ||
      cborDatumToDSDatum(datumCbor, sellerAddress.toBech32(), royalties);

    // Create a ChainUTxO and call buyAsset
    const chainUtxo: ChainUTxO = {
      address: utxo.address,
      txhash: utxo.tx_hash,
      index: utxo.index,
      value: utxo.assets
        .filter((a) => a.unit !== 'lovelace')
        .map((asset) => ({
          amount: asset.amount,
          // name: Buffer.from(asset.unit.substring(56), 'hex').toString('utf8'), // CIP68
          name: asset.unit.substring(56), // CIP68
          policy: asset.unit.substring(0, 56),
        })),
      lovelace: utxo.assets.filter((a) => a.unit === 'lovelace')[0].amount,
      assetowner: assetInfo.asset_owner,
      helios_version: assetInfo.helios_version || '13',
      datum: hash,
      script_cbor: assetInfo.script_bytes,
      supercube_enabled: !!assetInfo.supercube_enabled,
      type: assetInfo.contract_type,
      disbursements: assetInfo.disbursements,
      royalties,
      dsDatum: !json ? datumJson : undefined,
      inline_datum: json,
      onchain_datum: datumCbor,
      // chainUtxo.onchain_datum && !chainUtxo.inline_datum
    };

    return chainUtxo;
  }
  public async buyAsset(
    assetId: string,
    chainUtxo: ChainUTxO,
    txInputHash: string | undefined,
    txInputIndex: number | undefined,
    wallet: CardanoWalletExtended
  ) {
    console.log(
      'this._address.toBech32() !== chainUtxo.address',
      BigInt(chainUtxo.lovelace)
    );
    this.listener('Building');
    if (
      !this._address.validatorHash.eq(
        Address.fromBech32(chainUtxo.address).validatorHash
      )
    ) {
      console.error(
        'This Val Hash',
        this._address.validatorHash.hex,
        'Chain Val Hash',
        Address.fromBech32(chainUtxo.address).validatorHash.hex
      );

      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.UNEXPECTED,
        info: 'Script not for provided UTxO',
      });
    }

    // Check if we have either onchain_datum or inline_datum (inline_datum we need to verify it is a correct format)
    const sellerAddress = Address.fromHex(chainUtxo.assetowner.join(''));

    const [myDatum, inline] = this.extractDSDatum(chainUtxo);

    const networkParams = await this.getNetworkParams();
    const datum = await this.datum(myDatum);

    console.log(
      '>> >> Generated Datum Hash vs required',
      datum.hash,
      chainUtxo.datum
    );

    const redeemer = this.redeemer({ action: 'Buy' });

    const tx = new Tx();

    // -----------------------ADD INPUTS FROM WALLET-------------------------
    const amountFromWalletADA =
      BigInt(myDatum.datum.amount + 2_000_000) + BigInt(chainUtxo.lovelace);

    const addedWalletUTxOs = await this.addInputsFromWallet(
      amountFromWalletADA,
      tx,
      wallet
    );

    const assets = new Assets();
    chainUtxo.value.forEach((asset) => {
      const mph = MintingPolicyHash.fromHex(asset.policy);
      const token = hexToBytes(asset.name); //this would need to be CIP68 handled / hex to bytes.

      assets.addComponent(mph, token, BigInt(asset.amount));
    });

    const value = new Value(BigInt(chainUtxo.lovelace), assets);

    console.log('Asset Address', chainUtxo.address);

    const txOutput = new TxOutput(
      Address.fromBech32(chainUtxo.address),
      value,
      inline === 'HASHED' ? Datum.hashed(datum.pure) : Datum.inline(datum.pure)
    );

    const heliosUtxo = new UTxO(
      TxId.fromHex(chainUtxo.txhash) as TxId,
      BigInt(chainUtxo.index),
      txOutput
    );
    // Add Input from Script
    console.log('doBuy', 'Add Script input', '');
    tx.addInput(heliosUtxo, redeemer);

    // -------------------------------DS FEE---------------------------------
    let superCubeInUse = false;
    console.log('buy>>>', chainUtxo.supercube_enabled);
    if (chainUtxo.supercube_enabled) {
      // Find a SuperCube for policies being purchased

      const cubes = await Promise.all(
        chainUtxo.value.map((v) =>
          trpcClient.query('sc-for-policy', { policy: v.policy })
        )
      );

      if (cubes && cubes.length > 0) {
        const utxo = cubes[0]?.utxo;
        if (utxo) {
          superCubeInUse = true;

          const scAssets = new Assets();

          utxo.assets.forEach((asset) =>
            scAssets.addComponent(
              MintingPolicyHash.fromHex(asset.policy),
              Array.from(new TextEncoder().encode(asset.name)), // CIP68 - This is good as this is the SuperCube Asset which is always CIP25
              BigInt(asset.quantity)
            )
          );

          const origOutput = new TxOutput(
            Address.fromBech32(utxo.address),
            new Value(BigInt(utxo.lovelace), scAssets),
            Datum.inline(ListData.fromCbor(hexToBytes(utxo.datum)))
          );

          const superCubeRefInput = new TxRefInput(
            TxId.fromHex(utxo.txHash),
            BigInt(utxo.txIndex),
            origOutput
          );

          console.log('buy>>>>>', JSON.stringify(superCubeRefInput.dump()));

          tx.addRefInput(superCubeRefInput);
        }
      }
    }

    let dsFee = 0;
    if (!superCubeInUse) {
      let dsPct = fractionToScriptPct(0.02);
      if (myDatum.type === 'Sale-Mint') {
        dsPct = 1000 / myDatum.datum.dropspotRate;
      }
      console.log('DS Pct', dsPct, myDatum.type);

      dsFee = dsFeeCap(
        Math.ceil((myDatum.datum.amount * 10) / dsPct),
        chainUtxo.helios_version
      );

      console.log('BuyAsset', 'Add Dropspot Fee output', dsFee);
      tx.addOutput(
        this.createSafeTxOutput(
          networkParams,
          Address.fromBech32(constants.NEXT_PUBLIC_DROPSPOT_ADDRESS),
          new Value(BigInt(dsFee) + BigInt(chainUtxo.lovelace))
        )
      );
    }

    // -----------------------------ROYALTIES--------------------------------

    let royaltyTotal = 0;
    myDatum.datum.royalties.forEach((royalty) => {
      const amt = Math.ceil(
        (myDatum.datum.amount * 10) / fractionToScriptPct(royalty.percent)
      );
      console.log(
        'BuyAsset',
        'Add Royalty Output',
        royalty.address,
        amt,
        royalty.percent,
        fractionToScriptPct(royalty.percent),
        myDatum.datum.amount * 10
      );
      royaltyTotal += amt;

      tx.addOutput(
        this.createSafeTxOutput(
          networkParams,
          Address.fromBech32(royalty.address),
          new Value(BigInt(amt))
        )
      );
    });

    // -----------------------------DISBURSEMENTS-----------------------------

    const remainingAmount = myDatum.datum.amount - dsFee - royaltyTotal;

    let disbTotal = 0;
    myDatum.datum.disbursements.forEach((disbursement) => {
      const amt = Math.ceil(
        (remainingAmount * 10) / fractionToScriptPct(disbursement.percent)
      );
      console.log(
        'BuyAsset',
        'Add Disbursement Output',
        disbursement.address,
        amt
      );
      disbTotal += amt;

      tx.addOutput(
        this.createSafeTxOutput(
          networkParams,
          Address.fromBech32(disbursement.address),
          new Value(BigInt(amt))
        )
      );
    });

    // ----------------------------PAY THE SELLER-----------------------------

    const sellerAmt = remainingAmount - disbTotal;

    tx.addOutput(
      this.createSafeTxOutput(
        networkParams,
        sellerAddress,
        new Value(BigInt(sellerAmt))
      )
    );

    // -------------------------ADD TOKEN OUTPUT------------------------------

    const address = await getAddress(wallet);
    const walletAddress = Address.fromHex(address);

    tx.addOutput(this.createSafeTxOutput(networkParams, walletAddress, value));

    if (
      chainUtxo.ref_input_value &&
      txInputHash &&
      txInputIndex !== undefined
    ) {
      tx.addRefInput(
        new TxRefInput(
          TxId.fromHex(txInputHash) as TxId,
          BigInt(txInputIndex),
          new TxOutput(
            Address.fromBech32(chainUtxo.ref_input_value.address),
            new Value(BigInt(chainUtxo.ref_input_value.lovelace))
          )
        ),
        this._compiled
      );
    } else {
      tx.attachScript(this._compiled);
    }

    this.setTxValidityPeriod(tx, Number.parseInt(myDatum.datum.startDatePOSIX));

    this.listener('Finalizing');
    this.balanceAssets(
      walletAddress,
      addedWalletUTxOs.selectedUtxos,
      tx,
      networkParams
    );

    console.log('>>>>>>>>>>>> Transaction', JSON.stringify(tx.dump()));

    const { otherUtxos } = await ensureCollateralUTxO({
      wallet,
      ...addedWalletUTxOs,
    }).catch(() => {
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.NO_COLLATERAL,
        info: 'No Collateral available in wallet UTxO set',
      });
    });

    let builtTx: Tx;
    try {
      builtTx = await tx.finalize(networkParams, walletAddress, otherUtxos);
    } catch (e) {
      if (e instanceof Error) {
        e.name = 'DropspotMarketError';
      }

      captureException(e, {
        tags: {
          type: 'DropspotMarketError',
          step: 'Finalize',
        },
        extra: {
          utxo: {
            type: chainUtxo.type,
            txHash: chainUtxo.txhash,
            txIdx: chainUtxo.index,
          },
          txCBOR: bytesToHex(tx.toCbor()),
          buyerAddress: walletAddress.toBech32(),
          now: new Date(),
        },
      });

      throw e;
    }

    console.log('doBuy', builtTx.dump());
    const signatures = await this.signTransaction(builtTx, wallet);

    builtTx.addSignatures(signatures);

    console.log('doBuy', 'Submit Txn', builtTx.witnesses.dump());
    return this.submitTx(bytesToHex(builtTx.toCbor()), assetId);
  }

  /**
   * For this instance of 'Contract' create a Listing
   */
  public async createListing(
    assetId: string,
    policy: string,
    name: string,
    assetStandard: 'CIP68' | 'CIP25',
    price: number,
    wallet: CardanoWalletExtended,
    quantity = 1
  ) {
    console.log('In createListing', {
      policy,
      name,
      assetStandard,
      price,
      wallet,
      quantity,
    });

    this.listener('Building');
    const tx = new Tx();
    console.log('createListing', 'Created a Tx');

    const royalties = await trpcClient.query('policy-royalties', { policy });

    const walletUTXOs = await this.addInputsFromWallet(
      BigInt(10_000_000),
      tx,
      wallet,
      {
        mph: MintingPolicyHash.fromHex(policy),
        name: assetStandard == 'CIP68' ? hexToBytes(name) : stringToBytes(name), //CIP68
        quantity,
      }
    );

    const sellAsset = new Assets([
      [
        MintingPolicyHash.fromHex(policy),
        [
          [
            assetStandard == 'CIP68' ? hexToBytes(name) : stringToBytes(name),
            BigInt(quantity),
          ],
        ], //CIP68
      ],
    ]);

    const address = await getAddress(wallet);
    const walletAddress = Address.fromHex(address);

    const datum = await this.datum({
      type: 'Sale',
      datum: {
        version: '13',
        ownerAddress: walletAddress.toBech32(),
        amount: price,
        disbursements: [],
        royalties: royalties,
        policy,
        tokenName:
          assetStandard === 'CIP25' ? bytesToHex(textToBytes(name)) : name,
        assetStandard,
        startDatePOSIX: `0`,
      },
    });

    console.log('datum', royalties, datum.datum);

    const networkParams = await this.getNetworkParams();

    tx.addOutput(
      this.createSafeTxOutput(
        networkParams,
        this._address,
        new Value(BigInt(0), sellAsset),
        Datum.inline(datum.pure)
      )
    );

    this.setAddressTxMetadata(walletAddress, tx);

    this.balanceAssets(
      walletAddress,
      walletUTXOs.selectedUtxos,
      tx,
      networkParams,
      sellAsset
    );

    this.listener('Finalizing');
    const builtTx = await tx.finalize(
      networkParams,
      walletAddress,
      walletUTXOs.otherUtxos
    );

    const signatures = await this.signTransaction(builtTx, wallet);

    builtTx.addSignatures(signatures);

    console.log('createListing', 'builtTx', builtTx.dump());

    return this.submitTx(bytesToHex(builtTx.toCbor()), assetId);
  }

  private setAddressTxMetadata(address: Address, tx: Tx) {
    tx.addMetadata(406, address.toHex());
  }

  private setTxValidityPeriod(tx: Tx, startDate: number) {
    const now = dayjs();
    const end = dayjs().add(36, 'hours');

    if (startDate > now.toDate().getTime()) {
      // S1
      throw new DropspotMarketError({
        code: InternalErrorCode.VALIDITY_START_INTERVAL_NOT_MET,
        info: 'Start Date has yet to pass',
        type: 'INTERNAL',
      });
    }

    tx.validFrom(now.add(-5, 'minutes').toDate());
    tx.validTo(end.toDate());
  }

  private async addInputsFromWallet(
    amountFromWalletADA: BigInt,
    tx: Tx,
    wallet: CardanoWalletExtended,
    multiAsset?: { mph: MintingPolicyHash; name: number[]; quantity: number }
  ) {
    return addInputsFromWallet(amountFromWalletADA, tx, wallet, multiAsset);
  }

  public async signTransaction(tx: Tx, wallet: CardanoWalletExtended) {
    try {
      this.listener('Signing');
      const signed = await wallet.signTx(bytesToHex(tx.toCbor()), true);
      console.log('createListing', 'Signed Txn', signed);

      console.log('createListing', 'Add Signatures');

      const witnesses = TxWitnesses.fromCbor(hexToBytes(signed));
      return witnesses.signatures;
    } catch (err) {
      console.error('Signing Error', err);
      throw new DropspotMarketError({
        code: (err as APIError).code,
        info: (err as APIError).info,
        type: 'SIGN',
      });
    }
  }

  public async submitTx(tx: string, assetId: string) {
    console.log('Submit Tx', tx);
    this.listener('Submitting');

    const txHash = await submitTx(tx, [assetId]);

    this.listener('Submitted');
    return txHash;
  }

  public get compiled() {
    return this._compiled;
  }

  public address() {
    return this._address.toBech32();
  }

  public redeemer(redeemer: RedeemerAction) {
    const program = Program.new(DROPSPOT_MARKET_DATUM_AND_REDEEMER_LIST);
    switch (redeemer.action) {
      case 'Relist':
        return new program.types.Redeemer.Relist(
          new HInt(BigInt(redeemer.startDate)),
          new HInt(BigInt(redeemer.price))
        );
      case 'Buy':
        return new program.types.Redeemer.Buy();
      case 'Cancel':
        return new program.types.Redeemer.Cancel();
    }
  }

  async datum(myDatum: DSDatum) {
    return datum(myDatum);
  }

  // public async test() {
  //   try {
  //     let args = [
  //       new UplcDataValue(exportedForTesting.Site.dummy(), Datum.fromCbor(hexToBytes('d8799fa0a0581cd006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c311a056057c0d8799f581c1cd7eb4b8635854f55bfaa2651d272264bb82dccdfe67dfb593455204447485431ff1b00000184185f9632ff')).data),
  //       new UplcDataValue(exportedForTesting.Site.dummy(), new exportedForTesting.UplcData(). ),
  //       new UplcDataValue(exportedForTesting.Site.dummy(), scriptContext),
  //     ];

  //     const args: exportedForTesting.UplcValue[] = [
  //       ,
  //       'd8799fff',
  //       'd8799fd8799f9fd8799fd8799fd8799f5820bf0183b8e206524be8e244b1b20b3e19b37c0caee218813a06dff554753db807ff00ffd8799fd8799fd87a9f581c07c5e001873b85a027523a9cdd3d6f95f41818a4ba4bf9c9b0a9a370ffd87a9fffffa240a1401a001e8480581c1cd7eb4b8635854f55bfaa2651d272264bb82dccdfe67dfb59345520a1444748543101d87b9fd8799fa0a0581cd006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c311a056057c0d8799f581c1cd7eb4b8635854f55bfaa2651d272264bb82dccdfe67dfb593455204447485431ff1b00000184185f9632ffffd87a9fffffffd8799fd8799fd8799f5820bf0183b8e206524be8e244b1b20b3e19b37c0caee218813a06dff554753db807ff01ffd8799fd8799fd8799f581cd006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c31ffd8799fd8799fd8799f581c730d805b6a2cf67998f0fcd070ce0b6e85957fd758cf0ae348d265ebffffffffa240a1401b0000000252978333581c1cd7eb4b8635854f55bfaa2651d272264bb82dccdfe67dfb59345520a144474854311a00018697d8799fffd87a9fffffffff9fff9fd8799fd8799fd8799f581c24d7811ebd7aff17e6be4b2f7a3677886f9617973e119855da033bb9ffd8799fd8799fd8799f581cdabfdce2c315fedb09e4ba39dbc4345866257eee43d9302e3e4b4013ffffffffa140a1401a00226898d8799fffd87a9fffffd8799fd8799fd8799f581cd006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c31ffd8799fd8799fd8799f581c730d805b6a2cf67998f0fcd070ce0b6e85957fd758cf0ae348d265ebffffffffa240a1401b00000002528f1816581c1cd7eb4b8635854f55bfaa2651d272264bb82dccdfe67dfb59345520a144474854311a00018698d8799fffd87a9fffffffa140a1401a00048705a09fffa0d8799fd8799fd87a9f1b000001841868fae0ffd87a9fffffd8799fd87a9f1b00000184186a4ad0ffd87a9fffffff9f581cd006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c31ffa1d87a9fd8799fd8799f5820bf0183b8e206524be8e244b1b20b3e19b37c0caee218813a06dff554753db807ff00ffffd8799fffa0d8799f58200000000000000000000000000000000000000000000000000000000000000000ffffd87a9fd8799fd8799f5820bf0183b8e206524be8e244b1b20b3e19b37c0caee218813a06dff554753db807ff00ffffff'

  //     ]

  //     const result =  await this._compiled.run([],

  //     {
  //       onPrint: async function (msg) {
  //         console.log(msg);
  //         return;
  //       },
  //       onStartCall: async function (site, rawStack) {
  //         console.log("StartCall", site, rawStack);
  //         return false;
  //       },
  //       onEndCall: async function (site, rawStack) {
  //         console.log("EndCall", site, rawStack);
  //         return;
  //       },
  //       onIncrCost: function (cost) {
  //         console.log(cost);
  //         return;
  //       },
  //     });

  //     console.log(result.toString());

  //   } catch (e) {
  //     console.log(e);
  //   }

  // }
}

function percentageToContractPCT(percent: number) {
  return Math.floor(10 / percent);
}

function stringToBytes(str: string) {
  return Array.from(new TextEncoder().encode(str));
}

function joinStringIfArray(str: string | string[]) {
  if (typeof str === 'string') return str;
  return str.join('');
}

export async function getUtxos(
  wallet: CardanoWalletExtended,
  lovelace: BigInt,
  multiAsset?: { mph: MintingPolicyHash; name: number[]; quantity: number }
) {
  logger.debug({
    message: 'In getUtxos',
    level: 'Info',
    timestamp: Date.now(),
    additional: {
      lovelace: lovelace.toString(),
      multiAsset: multiAsset
        ? {
            policy: multiAsset.mph.hex,
            name: bytesToHex(multiAsset.name),
            quantity: multiAsset.quantity,
          }
        : undefined,
    },
  });
  const start = performance.now();
  let utxos: string[] | undefined = [];
  if (wallet.name === 'Flint Wallet') {
    utxos = await wallet.getUtxos();
  } else {
    let page = -1;

    try {
      while (true) {
        page++;

        const walletUtxos = await wallet.getUtxos(undefined, {
          page,
          limit: 100,
        });

        console.log('walletUtxos', page, walletUtxos);
        if (
          !walletUtxos || walletUtxos.length === 0 ||
          (utxos[utxos.length - 1] !== undefined &&
            utxos[utxos.length - 1] === walletUtxos[walletUtxos.length - 1])
        ) {
          break;
        }

        utxos.push(...walletUtxos);
      }
    } catch (err) {
      console.log(err);
    }
  }

  if (!utxos) {
    throw new DropspotMarketError({
      type: 'COIN_SELECTION',
      code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
      info: 'Not enough Coin to pay for transaction',
    });
  }

  const allUtxos = utxos.map((utxo) => UTxO.fromCbor(hexToBytes(utxo)) as UTxO);

  let asset: Assets | undefined = undefined;
  if (multiAsset) {
    asset = new Assets();
    asset.addComponent(
      multiAsset.mph,
      multiAsset.name,
      BigInt(multiAsset.quantity)
    );
  }

  const end = performance.now();
  logger.debug({
    message: 'getUTxOs completed',
    level: 'Info',
    timestamp: Date.now(),
    additional: {
      start: start,
      end,
      took: end - start,
      utxos: allUtxos.length,
    },
  });

  const networkParams = await getNetworkParams();
  return coinSelection(
    allUtxos,
    new Value(lovelace.valueOf(), asset),
    networkParams
  );
}

export async function addInputsFromWallet(
  amountFromWalletADA: BigInt,
  tx: Tx,
  wallet: CardanoWalletExtended,
  multiAsset?: { mph: MintingPolicyHash; name: number[]; quantity: number }
) {
  console.log('In addInputsFromWallet 2', amountFromWalletADA);

  // Get every UTxO from the Wallet, for flint thats just a call to wallet.getUtxos
  // for other Wallets we will need to page thru

  const [selectedUtxos, otherUtxos] = await getUtxos(
    wallet,
    amountFromWalletADA,
    multiAsset
  );
  console.log('coinSelection', selectedUtxos);
  tx.addInputs(selectedUtxos);

  return { selectedUtxos, otherUtxos };
}

export async function signTransaction(tx: Tx, wallet: Cardano) {
  try {
    const signed = await wallet.signTx(tx.toCborHex(), true);
    console.log('createListing', 'Signed Txn', signed);

    console.log('createListing', 'Add Signatures');

    const witnesses = TxWitnesses.fromCbor(hexToBytes(signed));
    return witnesses.signatures;
  } catch (err) {
    console.error('Signing Error', err);
    console.error('Signing Error', JSON.stringify(tx.dump()));
    throw new DropspotMarketError({
      code: (err as APIError).code,
      info: (err as APIError).info,
      type: 'SIGN',
    });
  }
}

export async function submitTx(tx: string, assetIds: string[]) {
  console.log('Submit Tx', tx);
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) throw new Error('No user');

  try {
    const response = await fetch(
      getAbsoluteURL(`/api/blockfrost/plutus/utils/txs/submit`),
      {
        method: 'POST',
        body: JSON.stringify({ tx, assetIds }),
        headers: {
          'Content-Type': 'application/cbor',
          Authorization: `Bearer ${await currentUser.getIdToken(true)}`,
        },
      }
    );

    if (!response.ok) {
      console.error(await response.json());
      throw new Error('Submit error');
    }

    return (await response.json()) as string;

    // const txHash = await this.wallet.submitTx(toHex(signedTx.to_bytes()));
    // return txHash;
  } catch (err) {
    console.error('Submit error');
    console.error(err);
    throw new DropspotMarketError({
      code: 2,
      info: (err as Error).message,
      type: 'SEND',
    });
  }
}

export function balanceAssets(
  walletAddress: Address,
  addedWalletUTxOs: UTxO[],
  tx: Tx,
  networkParams: NetworkParams,
  ignoreAssets?: Assets // These are assets that we do not want to create a 'change' output for.
) {
  let balanceAssets = addedWalletUTxOs
    .map((wUtxo) => wUtxo.value.assets)
    .reduce((allAssets, assets) => allAssets.add(assets), new Assets());

  console.log(bytesToHex(balanceAssets.toCbor()));
  if (ignoreAssets) {
    console.log('ignoreAssets', bytesToHex(ignoreAssets.toCbor()));
    balanceAssets = balanceAssets.applyBinOp(ignoreAssets, (a, b) => a - b);
  }

  console.log(bytesToHex(balanceAssets.toCbor()), balanceAssets.isZero());
  if (!balanceAssets.isZero()) {
    // Create a TXout to the Change Address with these Unbalanced Assets
    tx.addOutput(
      createSafeTxOutput(
        networkParams,
        walletAddress,
        new Value(BigInt(0), balanceAssets)
      )
    );
  }

  return tx;
}

export function createSafeTxOutput(
  networkParams: NetworkParams,
  address: Address,
  value: Value,
  datum?: Datum,
  refScript?: UplcProgram
) {
  const txOutput = new TxOutput(address, value, datum, refScript);
  txOutput.correctLovelace(networkParams);

  if (txOutput.value.lovelace < BigInt(1_000_000)) {
    txOutput.value.setLovelace(BigInt(1_000_000));
  }

  return txOutput;
}

type RedeemerAction =
  | {
      action: 'Buy' | 'Cancel';
    }
  | {
      action: 'Relist';
      price: string;
      startDate: string;
    };

export type DSDatum =
  | {
      type: 'Sale';
      datum: DSDatumSale;
    }
  | {
      type: 'Sale-Mint';
      datum: DSDatumMint;
    };

type DSDatumSale = {
  ownerAddress: string;
  amount: number;
  royalties: { percent: number; address: string }[];
  disbursements: { percent: number; address: string }[];
  policy: string;
  tokenName: string;
  assetStandard?: 'CIP68' | 'CIP25';
  startDatePOSIX: string;
  version: '12' | '13';
};

type DSDatumMint = DSDatumSale & {
  dropspotRate: number;
  cancelFee: number;
};

type X = { map: X[] } | { bytes: string } | { int: number } | DSInlineDatum;

type DSInlineDatum = {
  fields: X[];
  constructor: number;
};

/**
 * To turn a %age into something that we can use OnChain we need to do the Formula:
 * x = 10 / y
 * Where
 *  x is the Integer value for OnChain and
 *  y is the %age
 * @param frac
 * @returns
 */
function fractionToScriptPct(frac: number) {
  return Math.round(10 / frac);
}

/**
Function dsFeeCap
Version 13 contracts introduced a 'cap' both Upper and Lower on Dropspot Fees
This function will take a fee and return the capped fee for version 13 contracts
or just the Fee for version 12.
*/
function dsFeeCap(fee: number, version: '12' | '13') {
  if (version === '12') return fee;

  if (fee < 1_000_000) {
    return 1_000_000;
  }

  if (fee > 3_000_000) return 3_000_000;

  return fee;
}

/* Example DSInlineDatumNew
{
  "list": [
    {
      "map": [
        {
          "k": {
            "bytes": "4a3e488066ae83b7a92807f27561cbe6466f9fce85d200cfab447d27"
          },
          "v": {
            "int": 200
          }
        }
      ]
    },
    {
      "map": []
    },
    {
      "bytes": "d006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c31"
    },
    {
      "int": 150000000
    },
    {
      "fields": [
        {
          "bytes": "95d96d58b6fcb6c13c9145db634afb3e99d26ecead6b395f1da8dd5d"
        },
        {
          "bytes": "5453373233"
        }
      ],
      "constructor": 0
    },
    {
      "int": 0
    }
  ]
}
*/

function handleInlineDatum(
  helios_version: '12' | '13',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inMyDatum: any,
  ownerAddress: Address,
  royalties?: { address: string; percent: number }[],
  disbursements?: { address: string; percent: number }[]
): [DSDatum, 'INLINE' | 'HASHED'] {
  let myDatum: X[] | z.infer<typeof v13InlineList> = inMyDatum;

  console.log('handleInlineDatum', helios_version, inMyDatum);
  if (helios_version === '13') {
    const d = v13InlineDatumSchema.parse(inMyDatum);
    myDatum = d.list;
  } else {
    myDatum = inMyDatum.fields as X[];
  }

  if (!(myDatum.length === 6 || myDatum.length === 8))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 1',
    });
  if (!('fields' in myDatum[4]))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 2',
    });
  if (!('int' in myDatum[3]))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 3',
    });
  if (!('int' in myDatum[5]))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 4',
    });
  if (myDatum[4].fields.length !== 2)
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 5',
    });
  if (!('bytes' in myDatum[4].fields[0]))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 6',
    });
  if (!('bytes' in myDatum[4].fields[1]))
    throw new DropspotMarketError({
      type: 'INTERNAL',
      code: InternalErrorCode.MissingDatum,
      info: 'Bad inline datum - 7',
    });

  const [policy, asset] = myDatum[4].fields;
  const price = myDatum[3].int;
  const startDate = myDatum[5].int;

  if (myDatum.length === 8) {
    if (!('int' in myDatum[6]))
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Bad inline datum - 8',
      });
    if (!('int' in myDatum[7]))
      throw new DropspotMarketError({
        type: 'INTERNAL',
        code: InternalErrorCode.MissingDatum,
        info: 'Bad inline datum - 9',
      });

    const dropspotRate = 1000 / (myDatum[6] as IntDatum).int;
    const cancelFee = (myDatum[7] as IntDatum).int;
    return [
      {
        type: 'Sale-Mint',
        datum: {
          version: helios_version,
          ownerAddress: ownerAddress.toBech32(),
          amount: price,
          royalties: royalties ? royalties : [],
          disbursements: disbursements || [],
          policy: policy.bytes,
          tokenName: asset.bytes,
          startDatePOSIX: `${startDate}`,
          cancelFee,
          dropspotRate,
        },
      },
      'INLINE',
    ];
  }

  return [
    {
      type: 'Sale',
      datum: {
        version: helios_version,
        ownerAddress: ownerAddress.toBech32(),
        amount: price,
        royalties: royalties ? royalties : [],
        disbursements: disbursements || [],
        policy: policy.bytes,
        tokenName: asset.bytes,
        startDatePOSIX: `${startDate}`,
      },
    },
    'INLINE',
  ];
}

/*

  {
    "map": [
      {
        "k": {
          "bytes": "d006eb7783e8c93160b2bab287bc8a6f069e9e690cd82bc0b52a8c31"
        },
        "v": {
          "int": 200
        }
      }
    ]
  },
*/

const v13DiabInlineSchema = z.object({
  map: z.array(
    z.object({
      k: z.object({
        bytes: z.string(),
      }),
      v: z.object({
        int: z.number(),
      }),
    })
  ),
});

const v13AssetClass = z.object({
  fields: z.tuple([
    z.object({
      bytes: z.string(),
    }),
    z.object({
      bytes: z.string(),
    }),
  ]),
  constructor: z.number(),
});

const v13InlineList = z.tuple([
  v13DiabInlineSchema,
  v13DiabInlineSchema,
  z.object({
    bytes: z.string(),
  }),
  z.object({
    int: z.number(),
  }),
  v13AssetClass,
  z.object({
    int: z.number(),
  }),
]);
const v13InlineDatumSchema = z.object({
  list: v13InlineList,
});

function isInlineDatum(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datum: any,
  version: '12' | '13'
): datum is DSInlineDatum {
  // datum should have a Fields property
  if (typeof datum !== 'object' || !datum) return false;

  if (version === '13') {
    try {
      v13InlineDatumSchema.safeParse(datum);
      console.log('Valid v13 datum');
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  if (version === '12') {
    if (!('fields' in datum)) return false;

    if (!Array.isArray(datum.fields)) return false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isPossible = (datum.fields as Array<any>).every((v) => {
      return (
        typeof v.bytes === 'string' ||
        typeof v.int === 'number' ||
        'map' in v ||
        'fields' in v
      );
    });

    if (!isPossible) return false;

    console.log('Length', datum.fields.length);
    if (!(datum.fields.length === 6 || datum.fields.length === 8)) {
      console.log('Bad inline datum - 1');
      return false;
    }
    if (!('fields' in datum.fields[4])) {
      console.log('Bad inline datum - 2');
      return false;
    }
    if (!('int' in datum.fields[3])) {
      console.log('Bad inline datum - 3');
      return false;
    }
    if (!('int' in datum.fields[5])) {
      console.log('Bad inline datum - 4');
      return false;
    }
    if (datum.fields[4].fields.length !== 2) {
      console.log('Bad inline datum - 5');
      return false;
    }
    if (!('bytes' in datum.fields[4].fields[0])) {
      console.log('Bad inline datum - 6');
      return false;
    }
    if (!('bytes' in datum.fields[4].fields[1])) {
      console.log('Bad inline datum - 7');
      return false;
    }
    return true;
  }

  return false;
}

export async function datum(myDatum: DSDatum) {
  return myDatum.datum.version === '12' ? v12Datum(myDatum) : v13Datum(myDatum);
}

async function v13Datum(myDatum: DSDatum) {
  console.log('In v13Datum', myDatum);
  const {
    contracts: { dsMarketContract, superCubeModule },
  } = await import('@dropspot-io/contract-api');

  const program = Program.new(dsMarketContract, [superCubeModule]);

  const { Datum: DSDatum } = program.types;

  const r = new (HMap(PubKeyHash, HInt))(
    (myDatum.datum.royalties || []).map(({ address, percent }) => {
      return [
        Address.fromBech32(address).pubKeyHash,
        new HInt(percentageToContractPCT(percent)),
      ] as const;
    })
  );

  const d = new (HMap(PubKeyHash, HInt))(
    (myDatum.datum.disbursements || []).map(({ address, percent }) => {
      return [
        Address.fromBech32(address).pubKeyHash,
        new HInt(percentageToContractPCT(percent)),
      ] as const;
    })
  );

  const dsDatum = new DSDatum(
    r,
    d,
    Address.fromBech32(myDatum.datum.ownerAddress).pubKeyHash,
    new HInt(myDatum.datum.amount),
    new AssetClass([
      MintingPolicyHash.fromHex(myDatum.datum.policy),
      hexToBytes(myDatum.datum.tokenName),
    ]),
    new Time(BigInt(myDatum.datum.startDatePOSIX))
  );

  const datum = Datum.inline(dsDatum);

  return {
    hash: datum.hash.hex,
    datum: bytesToHex(datum.toCbor()),
    pure: dsDatum._toUplcData(),
  };
}

function v12Datum(myDatum: DSDatum) {
  console.log('In v12Datum', myDatum);

  const {
    datum: {
      ownerAddress,
      amount,
      royalties,
      disbursements,
      policy,
      tokenName,
      startDatePOSIX,
    },
  } = myDatum;

  // 1. Royalties

  const r = new MapData(
    (royalties || []).map(({ address, percent }) => {
      return [
        UplcData.fromCbor(Address.fromBech32(address).pubKeyHash.toCbor()),
        IntData.fromCbor(
          CborData.encodeInteger(BigInt(percentageToContractPCT(percent)))
        ),
      ] as [UplcData, UplcData];
    })
  );

  const d = new MapData(
    (disbursements || []).map(({ address, percent }) => {
      return [
        UplcData.fromCbor(Address.fromBech32(address).pubKeyHash.toCbor()),
        IntData.fromCbor(
          CborData.encodeInteger(BigInt(percentageToContractPCT(percent)))
        ),
      ] as [UplcData, UplcData];
    })
  );

  const owner = UplcData.fromCbor(
    Address.fromBech32(ownerAddress).pubKeyHash.toCbor()
  );

  const price = UplcData.fromCbor(CborData.encodeInteger(BigInt(amount)));

  const token = new ConstrData(0, [
    UplcData.fromCbor(CborData.encodeBytes(hexToBytes(policy))),
    UplcData.fromCbor(
      CborData.encodeBytes(
        hexToBytes(Buffer.from(tokenName, 'utf8').toString('hex'))
      )
    ),
  ]);

  const startDate = UplcData.fromCbor(
    CborData.encodeInteger(BigInt(startDatePOSIX))
  );

  const fields: UplcData[] = [r, d, owner, price, token, startDate];

  if (myDatum.type === 'Sale-Mint') {
    fields.push(
      IntData.fromCbor(
        CborData.encodeInteger(
          // dropspotRate is a 'real' number like 2.5% etc
          BigInt(Math.ceil(1000 / myDatum.datum.dropspotRate))
        )
      )
    );

    fields.push(
      IntData.fromCbor(CborData.encodeInteger(BigInt(myDatum.datum.cancelFee)))
    );
  }

  const whole = new ConstrData(0, fields);

  const datum = Datum.hashed(whole);

  return {
    hash: datum.hash.hex,
    datum: Buffer.from(whole.toCbor()).toString('hex'),
    pure: whole,
  };
}

export async function getNetworkParams() {
  return new NetworkParams(
    await fetch(constants.NETWORK_PARAMS_URL).then((r) => r.json())
  );
}

function cborDatumToDSDatum(
  datum: string,
  ownerAddress: string,
  royalties: { address: string; percent: number }[]
): DSDatum {
  const bytes = hexToBytes(datum);
  let type: DSDatum['type'];

  let datumRecord: Partial<DSDatumSale> | Partial<DSDatumMint> | undefined =
    undefined;

  datumRecord = {
    ownerAddress: ownerAddress,
    royalties,
    disbursements: [],
  };

  let price: BigInt;
  let token: UplcData[];
  let startDate: BigInt;
  let dropspotRate = BigInt(500);
  let cancelFee = BigInt(0);
  let version: '12' | '13' = '12';

  type = 'Sale';
  if (CborData.isList(bytes)) {
    // Version 13
    version = '13';
    const ld = ListData.fromCbor(bytes);

    price = ld.list[3].int;
    token = ld.list[4].fields;
    startDate = ld.list[5].int;
    if (ld.list.length === 8) {
      type = 'Sale-Mint';
      dropspotRate = ld.list[6].int;
      cancelFee = ld.list[7].int;
    }
  } else if (CborData.isConstr(bytes)) {
    // Version 12
    const cd = ConstrData.fromCbor(bytes);

    price = cd.fields[3].int;
    token = cd.fields[4].fields;
    startDate = cd.fields[5].int;

    if (cd.fields.length === 8) {
      type = 'Sale-Mint';
      dropspotRate = cd.fields[6].int;
      cancelFee = cd.fields[7].int;
    }
  } else {
    throw new Error('Unknown Datum Type');
  }

  datumRecord.amount = Number(price);
  datumRecord.policy = bytesToHex(token[0].bytes);
  datumRecord.tokenName = Buffer.from(
    bytesToHex(token[1].bytes),
    'hex'
  ).toString('utf8');
  datumRecord.version = '13';
  datumRecord.startDatePOSIX = startDate.toString();
  datumRecord.version = version;

  switch (type) {
    case 'Sale-Mint':
      return {
        type: 'Sale-Mint',
        datum: {
          ...(datumRecord as DSDatumMint),
          cancelFee: Number(cancelFee),
          dropspotRate: Number(dropspotRate),
        },
      };
    case 'Sale':
      return {
        type: 'Sale',
        datum: datumRecord as DSDatumSale,
      };
  }
}

/**
 * Get a Payment address, in Hex for the provided Wallet
 * @param wal The users Cardano Wallet
 * @returns
 */
export async function getAddress(wal: Cardano) {
  let addrs = await wal.getUsedAddresses();

  if (addrs.length > 0) {
    return addrs[0];
  }

  addrs = await wal.getUnusedAddresses();

  if (addrs.length > 0) {
    return addrs[0];
  }

  throw new Error('No Wallet Payment Addresses');
}

type MapDatum = { map: { k: BytesDatum; v: IntDatum }[] };
type BytesDatum = { bytes: string };
type IntDatum = { int: number };
type ValidDatum = {
  fields: (MapDatum | BytesDatum | IntDatum | ValidDatum)[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isValidDatumJson(d: any): d is ValidDatum {
  return 'fields' in d;
}

export async function updateAssetListing(
  assetId: string,
  price: number | null,
  txHash: string | null,
  marketStatus: 'LISTED' | 'UNLISTED' | 'OFFER',
  action: 'BUY' | 'LIST' | 'CANCEL' | 'RELIST',
  tradeOwner?: string,
  asset?: Asset
) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) return;

  const url = getAbsoluteURL('/api/updateAssetListing');

  const result = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${await currentUser.getIdToken()}`,
    },
    body: JSON.stringify({
      assetId,
      price,
      txHash,
      marketStatus,
      action,
      tradeOwner,
      ...asset || {},
    }),
  });

  if (!result.ok) {
    console.error(await result.json());
  }
}

export async function getWalletPKH(wal: CardanoWalletExtended) {
  let addrs = await wal.getUsedAddresses();

  if (addrs.length > 0) {
    return Address.fromHex(addrs[0]).pubKeyHash;
  }

  addrs = await wal.getUnusedAddresses();

  if (addrs.length > 0) {
    return Address.fromHex(addrs[0]).pubKeyHash;
  }

  throw new Error('No Wallet Payment Addresses');
}

const DROPSPOT_MARKET_DATUM_AND_REDEEMER_LIST = `
spending dropspotmarket
enum Redeemer {
  Buy
  Relist {
    startDate: Time
    price: Int
  }
  Cancel
}

func main(_,_,_) -> Bool {
  true
}

const REDEEMER_BUY: Redeemer::Buy = Redeemer::Buy {}
const REDEEMER_CANCEL: Redeemer::Cancel = Redeemer::Cancel {}

const NEW_PRICE_LOVELACE: Int = 0
const NEW_TIME:Int = 0

const REDEEMER_RELIST: Redeemer::Relist = Redeemer::Relist {
  startDate: Time::new(NEW_TIME),
  price: NEW_PRICE_LOVELACE
}
`;
