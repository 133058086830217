import React from 'react';

export type ButtonProps = {
  label: string;
  id?: string;
  size?: 'btn-xs' | 'btn-sm' | 'btn-md' | 'btn-lg';
  onClick: () => void;
  working?: boolean;
  workingLabel?: string;
  classNames?: string;
  isDisabled?: boolean;
  icon?: JSX.Element;
  colorName?:
    | 'btn-primary'
    | 'btn-secondary'
    | 'btn-accent'
    | 'btn-neutral'
    | 'btn-success'
    | 'btn-danger'
    | 'btn-warning'
    | 'btn-info'
    | 'btn-light'
    | 'btn-dark'
    | 'btn-ghost'
    | 'btn';

  didSelect?: () => void;
};

export default function DaisyButton({
  label,
  size = 'btn-md',
  colorName,
  classNames,
  didSelect,
  id,
  isDisabled = false,
  workingLabel = '',
  icon,
  working,
  onClick,
}: ButtonProps) {
  const cn = `btn ${colorName} ${size} ${classNames}  flex gap-2 items-center`;
  return (
    <button
      disabled={isDisabled}
      className={cn}
      id={id}
      onClick={() => !working && onClick()}
    >
      {icon}
      {working && <span className="loading loading-spinner loading-sm"></span>}
      {working ? workingLabel : label}
    </button>
  );
}
