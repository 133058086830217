import { bytesToHex, NetworkParams, UTxO, Value } from '@hyperionbt/helios';
import {
  DropspotMarketError,
  CoinSelectionErrorCode,
} from './DropspotMarketError';

import { logger } from '../Logger';

export function coinSelection(
  utxos: UTxO[],
  target: Value,
  networkParams?: NetworkParams
): Readonly<[UTxO[], UTxO[]]> {
  logger.debug({ message: 'In coinSelection', additional: { target, utxos } });
  const start = performance.now();
  const selected: Set<UTxO> = new Set();

  // Loop thru the target assets and select any UTxOs that have that asset
  target.assets.mintingPolicies.forEach((policy) => {
    target.assets.getTokenNames(policy).forEach((tokenName) => {
      const quantity = target.assets.get(policy, tokenName);

      // Get all UTxO's that have this token;
      // Sort them by quantity in descending order
      // Select the combination of UTxO's that add up to the quantity we need

      const assetCovered = utxos
        .filter((utxo) => utxo.value.assets.get(policy, tokenName) >= 0)
        .sort((a, b) =>
          a.value.assets.get(policy, tokenName) >
          b.value.assets.get(policy, tokenName)
            ? -1
            : a.value.assets.get(policy, tokenName) <
              b.value.assets.get(policy, tokenName)
            ? 1
            : 0
        )
        .some((utxo) => {
          selected.add(utxo);

          // Check we have selected enough to cover the Asset
          const selectedQty = Array.from(selected).reduce((acc, utxo) => {
            acc += utxo.value.assets.get(policy, tokenName);
            return acc;
          }, BigInt(0));

          return selectedQty >= quantity;
        });

      // If the asset is not covered then throw and Not enough coins Error
      if (!assetCovered) {
        throw new DropspotMarketError({
          type: 'COIN_SELECTION',
          info: `${policy.hex} ${bytesToHex(
            tokenName.bytes
          )} (${quantity}) - Not found in UTxO set`,
          code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
        });
      }
    });
  });

  // Finally work out if we have enough Lovelace to cover the target
  let selectedLovelace = Array.from(selected).reduce(
    (acc, utxo) => acc + calcAvailableLovelace(utxo, networkParams),
    BigInt(0)
  );

  if (target.lovelace > selectedLovelace) {
    // We don't have enough Lovelace, so we need to select some more UTxOs
    let remainingLovelace = target.lovelace - selectedLovelace;

    const selectedRemainingUTxOs = utxos
      .filter((utxo) => !selected.has(utxo))
      .sort((a, b) => {
        const availA = calcAvailableLovelace(a, networkParams);
        const availB = calcAvailableLovelace(b, networkParams);

        return availA > availB ? -1 : availA < availB ? 1 : 0;
      })
      .some((utxo) => {
        selected.add(utxo);
        // Reduce Remaining Lovelace
        const avail = calcAvailableLovelace(utxo, networkParams);
        // console.log('Add', avail, utxo.txId.hex, utxo.utxoIdx);
        remainingLovelace -= avail;
        // console.log(remainingLovelace);
        return remainingLovelace <= BigInt(0);
      });

    if (!selectedRemainingUTxOs) {
      throw new DropspotMarketError({
        type: 'COIN_SELECTION',
        info: `Not enough Lovelace to cover target - (${remainingLovelace})`,
        code: CoinSelectionErrorCode.INPUTS_EXHAUSTED,
      });
    }
  }

  selectedLovelace = Array.from(selected).reduce((acc, utxo) => {
    const avail = calcAvailableLovelace(utxo, networkParams);
    // console.log('Avail', avail, utxo.txId.hex, utxo.utxoIdx);
    return acc + avail;
  }, BigInt(0));

  if (target.lovelace > selectedLovelace) {
    throw new DropspotMarketError({
      type: 'COIN_SELECTION',
      info: `Not enough Available Lovelace to cover target - wanted ${
        target.lovelace
      } got ${selectedLovelace} (${target.lovelace - selectedLovelace})`,
      code: CoinSelectionErrorCode.INPUTS_LOCKED,
    });
  }

  const remainingUTxOs = utxos.filter((utxo) => !selected.has(utxo));

  const end = performance.now();
  logger.debug({
    message: 'Out coinSelection',
    additional: {
      functionTook: end - start,
      selected: Array.from(selected).map((u) => `${u.txId.hex} - ${u.utxoIdx}`),
      remainingUTxOs: remainingUTxOs.map((u) => `${u.txId.hex} - ${u.utxoIdx}`),
    },
  });
  return [Array.from(selected), remainingUTxOs] as const;
}

function calcAvailableLovelace(utxo: UTxO, networkParams?: NetworkParams) {
  if (!networkParams) return utxo.value.lovelace;
  if (utxo.value.assets.isZero()) return utxo.value.lovelace; // If we are clean then we can use all the Lovelace

  const minLovelace = utxo.origOutput.calcMinLovelace(networkParams);
  return utxo.value.lovelace - minLovelace;
}
