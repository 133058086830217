import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { DaisyInputColors, DasiySizes } from './DaisyTypes';
import { useState } from 'react';
import cn from 'classnames';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@nextui-org/react';

export interface TextInputProps {
  id: string;
  label: string;
  size?: DasiySizes;
  type?: string;
  fancy?: boolean;
  colorName?: DaisyInputColors;
  specialIcon?: JSX.Element;
  maxLength?: number;
  constainerClassName?: string;
  textArea?: 'textarea-xs' | 'textarea-sm' | 'textarea-md' | 'textarea-lg';
  min?: string;
  autoComplete?: string;
  rounded?: boolean;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string | number | undefined;
  infoText?: string | undefined;
  onChange: (text: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  error?: string;
  labelPosition?: 'sr-only' | 'top' | 'start' | 'inside' | 'hero';
  className?: string;
  icon?: () => JSX.Element;
  autoFocus?: boolean;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

export function DaisyTextInput({
  id,
  label,
  size = 'input-md',
  type = 'text',
  fancy = false,
  colorName,
  textArea,
  maxLength,
  min,
  autoComplete,
  specialIcon,
  required,
  placeholder,
  value = '',
  infoText,
  onChange,
  onBlur,
  onFocus,
  rounded,
  error,
  className,
  constainerClassName,
  disabled,
  labelPosition = 'sr-only',
  autoFocus = false,
  icon,
  onPaste,
}: TextInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  if (!!textArea) {
    return (
      <div className="form-control ">
        <label className="label">
          <span className="label-text">{label}</span>
          <span className="label-text-alt">
            {!error && (
              <span className="flex gap-1 items-center">
                <CheckCircleIcon className="w-4 h-4" />
                OK
              </span>
            )}
            {error && (
              <p
                className="text-sm text-error-500 font-normal w-full rounded-lg mt-1"
                id={`${id}-error`}
                data-testid={`${id}-error`}
              >
                {error}
              </p>
            )}
          </span>
        </label>
        <textarea
          className={`textarea  ${textArea} ${className} textarea-${size}} ${
            isFocused ? 'textarea-secondary' : ''
          }`}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={value || ''}
          id={id}
          name={id}
          required={required}
          disabled={disabled}
          onBlur={() => {
            setIsFocused(false);
            onBlur && onBlur();
          }}
          onFocus={() => {
            setIsFocused(true);
            onFocus && onFocus();
          }}
          onChange={({ currentTarget: { value } }) => {
            (!maxLength || value.length <= maxLength) && onChange(value);
          }}
        ></textarea>
        <label className="label">
          <span className="label-text-alt"> {infoText}</span>
          <span className="label-text-alt">
            {' '}
            {maxLength && (
              <div className="flex items-end justify-end">
                <p className="text-gray-500 text-sm m-1">
                  {(value as string).length}/{maxLength}
                </p>
              </div>
            )}
          </span>
        </label>
      </div>
    );
  }

  if (fancy) {
    return (
      <>
        <div
          className={`form-control w-full relative flex justify-center ${constainerClassName}`}
        >
          <label
            htmlFor={id}
            className={
              'absolute inset-y-0 left-0 pl-6 flex pt-6 text-gray-600 font-light text-sm pointer-events-none'
            }
          >
            {label}
          </label>
          {labelPosition == 'inside' && !!infoText && (
            <span className="w-4 h-4 absolute inset-y-0 right-4 top-3">
              <Tooltip content={infoText} placement={'left'}>
                <InformationCircleIcon className="w-5 h-5 text-gray-500 font-light text-sm hover:text-accent" />
              </Tooltip>
            </span>
          )}
          <input
            type={type}
            placeholder={placeholder}
            className={`input w-full input-lg h-[6rem] border border-gray-400 bg-base-200 pt-6 text-xl ${colorName} ${className} ${
              isFocused ? 'input-secondary' : ''
            }`}
            onPaste={onPaste}
            autoFocus={autoFocus}
            value={value || ''}
            id={id}
            name={id}
            min={min}
            required={required}
            disabled={disabled}
            onBlur={() => {
              setIsFocused(false);
              onBlur && onBlur();
            }}
            onFocus={() => {
              setIsFocused(true);
              onFocus && onFocus();
            }}
            onChange={({ currentTarget: { value } }) => {
              (!maxLength || value.length <= maxLength) && onChange(value);
            }}
          />

          {maxLength && (
            <div className="flex items-end justify-end -mt-9 mr-3">
              <p className="text-gray-500 text-sm m-1">
                {(value as string).length}/{maxLength}
              </p>
            </div>
          )}
          {error && (
            <ExclamationCircleIcon className="w-6 h-6 absolute right-2 top-0 bottom-0 m-auto text-error-500" />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={`form-control w-full relative flex justify-center ${constainerClassName}`}
      >
        <label className="label">
          <span className="label-text">{label}</span>
          <span className="label-text-alt">
            {' '}
            {!error && (
              <span className="flex gap-1 items-center">
                {required && !!value && (
                  <>
                    {' '}
                    <CheckCircleIcon className="w-4 h-4" />
                    OK
                  </>
                )}
                {required && !value && <> Required</>}
              </span>
            )}
            {error && (
              <p
                className="text-sm text-error-500 font-normal w-full rounded-lg mt-1"
                id={`${id}-error`}
                data-testid={`${id}-error`}
              >
                {error}
              </p>
            )}
          </span>
        </label>
        {!!specialIcon ? (
          <label className="input-group">
            {!!specialIcon && (
              <span className="w-14 text-gray-600 dark:text-gray-400">
                {' '}
                {specialIcon}
              </span>
            )}
            <input
              type={type}
              placeholder={placeholder}
              className={`input w-full  border border-gray-400 bg-base-200 ${size} ${colorName} ${className} ${
                isFocused ? 'input-secondary' : ''
              }`}
              onPaste={onPaste}
              autoFocus={autoFocus}
              value={value || ''}
              id={id}
              name={id}
              min={min}
              required={required}
              disabled={disabled}
              onBlur={() => {
                setIsFocused(false);
                onBlur && onBlur();
              }}
              onFocus={() => {
                setIsFocused(true);
                onFocus && onFocus();
              }}
              onChange={({ currentTarget: { value } }) => {
                (!maxLength || value.length <= maxLength) && onChange(value);
              }}
            />
          </label>
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            className={`input w-full  border border-gray-400 bg-base-200 ${size} ${colorName} ${className} ${
              isFocused ? 'input-secondary' : ''
            }`}
            onPaste={onPaste}
            autoFocus={autoFocus}
            value={value || ''}
            id={id}
            name={id}
            min={min}
            required={required}
            disabled={disabled}
            onBlur={() => {
              setIsFocused(false);
              onBlur && onBlur();
            }}
            onFocus={() => {
              setIsFocused(true);
              onFocus && onFocus();
            }}
            onChange={({ currentTarget: { value } }) => {
              (!maxLength || value.length <= maxLength) && onChange(value);
            }}
          />
        )}

        <label className="label text-base-content">
          <span className="label-text-alt">{infoText}</span>
          <span className="label-text-alt">
            {' '}
            {maxLength && (
              <div className="flex items-end justify-end">
                <p className="text-gray-500 text-sm m-1">
                  {(value as string).length}/{maxLength}
                </p>
              </div>
            )}
          </span>
        </label>
      </div>
    </>
  );
}
